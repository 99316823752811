import React from "react";
import './Footer.css';

export class Footer extends React.Component<any, any> {
  render() {
    const year = (new Date()).getFullYear();
    return (
      <footer>
        <div className="footer-top">
          <div className="logo-box">
            <div className="logo">
                <img src="/assets/aratani-foundation-logo.png" alt="Aratani Foundation"/>
            </div>
            <div className="logo">
              <a href="https://arts.ca.gov" target="_blank" rel="noopener noreferrer">
                <img src="/assets/CAC_logo_white.png" alt="California Arts Council"/>
              </a>
            </div>
            <div className="logo">
              <a href="https://www.calhum.org" target="_blank" rel="noopener noreferrer">
                <img src="/assets/calhum-logo.png" alt="California Humanities"/>
              </a>
            </div>
            <div className="logo">
              <a href="https://mellon.org" target="_blank" rel="noopener noreferrer">
                <img src="/assets/mellon-logo.png" alt="Mellon Foundation"/>
              </a>
            </div>
            <div className="logo">
              <a href="https://mikekelleyfoundation.org" target="_blank" rel="noopener noreferrer">
                <img src="/assets/mkf-logo.jpg" alt="Mike Kelley Foundation"/>
              </a>
            </div>
            <div className="logo">
              <a href="https://www.neh.gov" target="_blank" rel="noopener noreferrer">
                <img src="/assets/neh-preferred-seal.png" alt="National Endowment for the Humanities"/>
              </a>
            </div>
            <div className="logo">
              <a href="https://www.vcmedia.org" target="_blank" rel="noopener noreferrer">
                <img src="/assets/vc-long-logo.png" alt="Visual Communications"/>
              </a>
            </div>
          </div>
        </div>   
        <div className="footer-top">
          <div className="attribution-box" style={{ fontSize: '0.9em' }} data-build_date="2024-03-08T01:18:39Z">
              Copyright &copy; { year } Visual Communications &bull; Made by <a href="https://whatis.media/" target="_blank" rel="noopener noreferrer"><strong>What Is Media Even</strong></a> &bull; Build 253
          </div>
          <div className="social-box">
            <a className="social-icon" href="https://www.facebook.com/vcmediaorg" target="_blank" rel="noopener noreferrer">
                <i className="fa fa-facebook-square" />
            </a>
            <a className="social-icon" href="https://instagram.com/vcmediaorg" target="_blank" rel="noopener noreferrer">
                <i className="fa fa-instagram" />
            </a>
            <a className="social-icon" href="https://twitter.com/vcmediaorg" target="_blank" rel="noopener noreferrer">
                <i className="fa fa-twitter-square" />
            </a>
            <a className="social-icon" href="https://youtube.com/VisualCommunications" target="_blank" rel="noopener noreferrer">
                <i className="fa fa-youtube-square" />
            </a>
          </div>
        </div>     
      </footer>
      );
    }
  }

import React from 'react';
import './PanoramaScroller.css';
import { PanoDot } from "../PanoDot/PanoDot";
import dots from '../../assets/dots.json';
import { PanoHintBox } from "./PanoHintBox/PanoHintBox";
import {StreetSign} from "./StreetSign/StreetSign";

const dotList: PanoDotItem[] = dots;

export type PanoDotItem = {
  x: string;
  y: string;
  data: any;
};

export type PanoramaScrollerProps = {
  urls: string[];
  handleModalOverlay: any;
  isModalOpen: boolean;
};

type PanoImageOffset = {
  offset: number;
  label: string;
}

type PanoramaScrollerState = {
  panoDimensions: {
    x: number;
    y: number;
  };
  panoImageOffsets: PanoImageOffset[];
  currentStreetName: string;
  hidePanoHintBox: boolean;
}

export class PanoramaScroller extends React.Component<PanoramaScrollerProps, PanoramaScrollerState> {

  private panoRef: any;

  private pos = {
    left: 0,
    top: 0,
    x: 0,
    y: 0,
  };

  private mouseAbortController: AbortController;


  constructor(props: PanoramaScrollerProps) {
    super(props);
    this.state = {
      panoDimensions: { x: -1, y: -1 },
      panoImageOffsets: [],
      currentStreetName: '',
      hidePanoHintBox: false
    };
    this.pos = {
      left: 0,
      top: 0,
      x: 0,
      y: 0,
    }
    this.mouseAbortController = new AbortController();
    this.mouseAbortController.abort();
  }

  componentDidMount() {
    window.addEventListener('resize', this.resetImageWidth.bind(this));
    const images = this.panoRef.querySelectorAll('img');
    for (const image of images) {
      image.addEventListener('load', this.resetImageWidth.bind(this));
    }
    document.addEventListener('scroll', this.handleScroll.bind(this), true);
    document.addEventListener('wheel', (e) => {
      e.preventDefault();
      if (!this.props.isModalOpen) {
        if (e.deltaX !== 0) {
          this.panoRef.scrollLeft += e.deltaX;
        } else {
          this.panoRef.scrollLeft += e.deltaY;
        }
      }
    });
    this.panoRef.addEventListener('click', (e: any) => {
      const posX = e.layerX;
      const posY = e.layerY;
      const panoWidth = this.state.panoDimensions.x;
      const panoHeight = this.state.panoDimensions.y;
      const percentX = (posX / panoWidth * 100).toFixed(4);
      const percentY = (posY / panoHeight * 100).toFixed(4);
      console.log(`x: ${posX}/${panoWidth}px (${percentX}) | y: ${posY}/${panoHeight}px (${percentY})`);
    });
    this.panoRef.addEventListener('mousedown', this.handleMouseDown.bind(this));
  }

  componentWillUnmount() {
    document.removeEventListener('scroll', this.handleScroll.bind(this));
    window.removeEventListener('resize', this.resetImageWidth.bind(this));
    this.panoRef.removeEventListener('mousedown', this.handleMouseDown.bind(this));
  }

  handleScroll() {
    // Show/hide hint bot
    if (!this.state.hidePanoHintBox && this.panoRef.scrollLeft > 100) {
      this.setState({
        hidePanoHintBox: true
      });
    } else if (this.state.hidePanoHintBox && this.panoRef.scrollLeft <= 100) {
      this.setState({
        hidePanoHintBox: false
      })
    }

    // Set street name
    let currentStreetName = '';
    for (const street of this.state.panoImageOffsets) {
      if (street.offset <= this.panoRef.scrollLeft + (window.innerWidth / 2)) {
        currentStreetName = street.label;
      } else {
        break;
      }
    }
    if (this.state.currentStreetName !== currentStreetName) {
      this.setState({ currentStreetName });
    }
  }

  handleMouseDown(e: any) {
    this.pos = {
      // The current scroll
      left: this.panoRef.scrollLeft,
      top: this.panoRef.scrollTop,
      // Get the current mouse position
      x: e.clientX,
      y: e.clientY,
    };
    this.panoRef.style.cursor = 'grabbing';
    if (this.mouseAbortController instanceof AbortController) {
      this.mouseAbortController.abort();
    }
    this.mouseAbortController = new AbortController();
    this.panoRef.addEventListener('mousemove', this.handleMouseMove.bind(this), { signal: this.mouseAbortController.signal } as any);
    this.panoRef.addEventListener('mouseup', this.handleMouseUp.bind(this), { signal: this.mouseAbortController.signal } as any);
  }

  handleMouseMove(e: any) {
    // How far the mouse has been moved
    const dx = e.clientX - this.pos.x;
    const dy = e.clientY - this.pos.y;

    // Scroll the element
    this.panoRef.scrollTop = this.pos.top - dy;
    this.panoRef.scrollLeft = this.pos.left - dx;
  };

  handleMouseUp(e: any) {
    this.panoRef.style.cursor = 'grab';
    this.mouseAbortController.abort();
  };


  resetImageWidth() {
    // Reset panorama dimensions if needed
    const panoWidth = this.panoRef.scrollWidth;
    const panoHeight = this.panoRef.scrollHeight;
    if (this.state.panoDimensions.x !== panoWidth || this.state.panoDimensions.y !== panoHeight) {
      this.setState({ panoDimensions: { x: panoWidth, y: panoHeight } });
    }

    // Reset image offsets if needed
    const imageList = document.querySelectorAll('.pano-images img');
    const imageOffsets: PanoImageOffset[] = Array.from(imageList).map((el):PanoImageOffset => {
      return  {
        offset: (el as HTMLElement).offsetLeft,
        label: el.getAttribute('data-street-name') || 'Unknown'
      };
    });
    if (this.state.panoImageOffsets !== imageOffsets) {
      this.setState({ panoImageOffsets: imageOffsets });
      this.handleScroll();
    }
  }

  render() {
    return (
      <div className="pano-scroller" ref={ elem => this.panoRef = elem }>
        <PanoHintBox hideBox={ this.state.hidePanoHintBox } />
        <StreetSign streetName={ this.state.currentStreetName }/>
        <div className="pano-images">
          { dotList.map(dot => (<PanoDot
              key={ dot.data.address }
              percentX={ dot.x }
              percentY={ dot.y }
              data={ dot.data }
              panoDimensions={ this.state.panoDimensions }
              handleModalOverlay={ this.props.handleModalOverlay }
          />)) }
          <img draggable="false" alt="Panorama of Judge John Aiso Street" data-street-name="Judge John Aiso Street" src={ this.props.urls[0] } />
          <img draggable="false" alt="Panorama of E 1st Street" data-street-name="E 1st Street" src={ this.props.urls[1] } />
          <img draggable="false" alt="Panorama of N Central Ave" data-street-name="N Central Ave" src={ this.props.urls[2] } />
        </div>
      </div>
    )
  }
}

import React from 'react';
import './WelcomeModal.css';

type WelcomeModalProps = {
  handleClosingWelcomeModal: Function;
  showModal: boolean;
}

export class WelcomeModal extends React.Component<WelcomeModalProps, {}> {

  private comp: any;

  handleCloseModal = () => {
    document.removeEventListener('keydown', this.escapeKeyCloseModal.bind(this));
    this.comp.removeEventListener('click', this.clickOutsideModal.bind(this));
    this.props.handleClosingWelcomeModal();
  }

  componentDidMount() {
    document.addEventListener('keydown', this.escapeKeyCloseModal.bind(this));
    this.comp.addEventListener('click', this.clickOutsideModal.bind(this));
  }

  escapeKeyCloseModal (event: any){
    if(event.keyCode === 27) {
      this.handleCloseModal();
    }
  }

  clickOutsideModal (event: any) {
    if (event.target === this.comp) {
      this.handleCloseModal();
    }
  }

  render() {
    
  return (
    <div ref={elem => this.comp = elem} className={ this.props.showModal ? 'overlay-modal overlay-modal-show' : 'overlay-modal overlay-modal-hide'}>
      <div className="modal-outer-container">
        <div className="modal-body-container">
          <div className="modal-header"><h1>Welcome to First Street North: Open</h1></div>
          <div className="modal-caption">
            <div className="modal-body welcome">
              <div>Scroll left and right to explore the streets.</div>
              <div><i style={{ fontSize: '2.5em'}} className="fa fa-arrows-h"></i></div>
              <div>Click on each exploration dot to learn more about the building's history.</div>
              <div><i style={{ fontSize: '2em'}} className="fa fa-mouse-pointer"></i></div>
            </div>
            <div className="modal-close-button" aria-hidden="true" onClick={ this.handleCloseModal }>
              <i className="fa fa-check-circle"></i> Continue to Exhibit
            </div>
          </div>
        </div>
      </div>
    </div>
  );
  }
}


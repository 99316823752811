import React from 'react';
import BurgerMenu from '../BurgerMenu/BurgerMenu';
import './Header.css';

export class Header extends React.Component<{}, {}> {
  render() {
    return (
      <header className="first-street-north-header">
        <BurgerMenu />
      </header>
    );
  }
}

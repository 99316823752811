import React from 'react';
import './StreetSign.css';

type StreetSignProps = {
  streetName: string;
};

export class StreetSign extends React.Component<StreetSignProps, {}> {
  render() {
    return (
      <div className="pano-street-sign-container">
        <div className="pano-street-sign">
          { this.props.streetName }
        </div>
      </div>
    );
  }
}
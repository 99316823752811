import React from 'react';
import './App.css';
import { BrowserRouter, Route, Link, Switch } from 'react-router-dom';

// Transitioned to functional React components
import AboutPage from './components/AboutPage/AboutPage';
import ContactUsPage from './components/ContactUsPage/ContactUsPage';
import ContributionsPage from './components/ContributionsPage/ContributionsPage';

// Classic React components
import { Header } from './components/Header/Header';
import { Footer } from './components/Footer/Footer';
import { PanoramaScroller } from "./components/PanoramaScroller/PanoramaScroller";
import { InfoModal } from "./components/InfoModal/InfoModal";
import { PanoDotProps } from "./components/PanoDot/PanoDot";
import { WelcomeModal } from './components/WelcomeModal/WelcomeModal';
import { TimelineModal } from './components/TimelineModal/TimelineModal';

type FsnAppState = {
  lastSelectedPanoDotProps: PanoDotProps | null;
  timelineModalPanoDotProps: PanoDotProps | null;
  mouseLocationX: number;
  mouseLocationY: number;
  activePage: string;
  showWelcomeModal: boolean;
  showTimelineModal: boolean;
  showInfoModal: boolean;
};

export default class App extends React.Component<{}, FsnAppState> {

  private readonly urls = [
    '/assets/aiso/sketch-v3.jpg',
    '/assets/fs/sketch-v3.jpg',
    '/assets/central/sketch-v2.jpg'
  ];

  constructor(props: any) {
    super(props);
    this.state = {
      lastSelectedPanoDotProps: null,
      timelineModalPanoDotProps: null,
      mouseLocationX: 0,
      mouseLocationY: 0,
      activePage: 'app',
      showWelcomeModal: true,
      showTimelineModal: false,
      showInfoModal: false
    }
  }

  componentDidMount() {
    document.body.addEventListener("mousemove", this.setMouseCoords.bind(this));
  }

  setMouseCoords(event: MouseEvent) {
    if (this.state.mouseLocationX !== event.x || this.state.mouseLocationY !== event.y) {
      this.setState({ mouseLocationX: event.x, mouseLocationY: event.y });
    }
  }

  resetModals = () => {
    this.setState({
      showInfoModal: false,
      showTimelineModal: false,
      showWelcomeModal: false
    });
  }

  handleInfoModal = (panoDotProps: PanoDotProps) => {
    this.resetModals();
    this.setState({
      lastSelectedPanoDotProps: panoDotProps,
      showInfoModal: true
    });
  }

  handleTimelineModal = (panoDotProps: PanoDotProps) => {
    this.setState({
      timelineModalPanoDotProps: panoDotProps,
      showTimelineModal: true
    });
  }

  handleClosingTimelineModal = () => {
    this.setState({
      timelineModalPanoDotProps: null,
      showTimelineModal: false
    });
  }

  handleClosingWelcomeModal = () => {
    this.resetModals();
    this.setState({
      lastSelectedPanoDotProps: null,
      timelineModalPanoDotProps: null
    })
  }

  handleClosingInfoModal = () => {
    this.setState({
      lastSelectedPanoDotProps: null,
      showInfoModal: false
    });
  }

  render() {
    const timelineModalComponent = (
      (this.state.showTimelineModal && this.state.timelineModalPanoDotProps) ? 
        <TimelineModal handleModalClose={this.handleClosingTimelineModal} panoDotProps={this.state.timelineModalPanoDotProps} /> : ''
    );
    return (
      <BrowserRouter>
        <div className="App">
          <div className="screen">
            <Header />
            <Switch>
              <Route path="/about">
                <AboutPage />
              </Route>
              <Route path="/contact-us">
                <ContactUsPage />
              </Route>
              <Route path="/contributions">
                <ContributionsPage />
              </Route>
              <Route path="/">
                <PanoramaScroller urls={this.urls} isModalOpen={this.state.showTimelineModal} handleModalOverlay={this.handleInfoModal} />
                <InfoModal panoDotProps={this.state.lastSelectedPanoDotProps} handleModalClose={this.handleClosingInfoModal} handleTimelineModal={this.handleTimelineModal} />
                { timelineModalComponent }
                <WelcomeModal showModal={this.state.showWelcomeModal} handleClosingWelcomeModal={this.handleClosingWelcomeModal} />
              </Route>
            </Switch>
            <Footer />
          </div>
        </div>
      </BrowserRouter>

    );
  }
}


export function Home() {
  return (
    <>
      <main>
        <h2>Welcome to the homepage!</h2>
        <p>You can do this, I believe in you.</p>
      </main>
      <nav>
        <Link to="/about">About</Link>
      </nav>
    </>
  );
}

export function About() {
  return (
    <>
      <main>
        <h2>Who are we?</h2>
        <p>
          That feels like an existential question, don't you
          think?
        </p>
      </main>
      <nav>
        <Link to="/">Home</Link>
      </nav>
    </>
  );
}
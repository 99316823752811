import React from 'react';
import './InfoModal.css';
import { isNull } from 'lodash';
import { PanoDotProps } from "../PanoDot/PanoDot";

type InfoModalState = {
  showModal: boolean;
}

type InfoModalProps = {
  panoDotProps: PanoDotProps | null;
  handleModalClose: Function;
  handleTimelineModal: Function;
}

export class InfoModal extends React.Component<InfoModalProps, InfoModalState> {

  private comp: any;

  constructor(props: any) {
    super(props);
    this.state = {
      showModal: true
    };
  }

  handleCloseModal = () => {
    document.removeEventListener('keydown', this.escapeKeyCloseModal.bind(this));
    if (this.comp) this.comp.removeEventListener('click', this.clickOutsideModal.bind(this));
    this.setState({ showModal: false });
    this.props.handleModalClose();
  };

  handleTimelineModal = () => {
    this.props.handleTimelineModal(this.props.panoDotProps);
    this.handleCloseModal();
  };

  componentDidUpdate(prevProps: any) {
    if (this.props.panoDotProps !== null && (this.props.panoDotProps !== prevProps.panoDotProps)) {
      this.setState({ showModal: true });
      document.addEventListener('keydown', this.escapeKeyCloseModal.bind(this));
      this.comp.addEventListener('click', this.clickOutsideModal.bind(this));
    }
  }

  escapeKeyCloseModal (event: any){
    if(event.keyCode === 27 && this.state.showModal) {
      this.handleCloseModal();
    }
  }

  clickOutsideModal (event: any) {
    if (event.target === this.comp) {
      this.handleCloseModal();
    }
  }

  render() {
    let headerBlock = <div></div>;
    let embedBlock = <div></div>;
    let timelineModalBlock = <div></div>;

    if (this.props.panoDotProps && this.props.panoDotProps.data.name) {
      headerBlock = (
        <div className="modal-header">
          <h1>{ this.props.panoDotProps.data.address }</h1>
          <h2>{ this.props.panoDotProps.data.name }</h2>
        </div>
      );
    } else if (this.props.panoDotProps && this.props.panoDotProps.data.address) {
      headerBlock = (
        <div className="modal-header">
          <h1>{ this.props.panoDotProps.data.address }</h1>
        </div>
      );
    }

    if (this.props.panoDotProps && this.props.panoDotProps.data.vimeo_id) {
      embedBlock = (
        <div className="modal-media">
          <div style={{ padding: "56.25% 0 0 0", position: "relative" }}>
            <iframe title="sample vimeo video"
                    src={ `https://player.vimeo.com/video/${ this.props.panoDotProps.data.vimeo_id }`}
                    style={{ position: "absolute", top: 0, left:0, width: "100%", height: "100%"}}
                    frameBorder="0"
                    allow="fullscreen" allowFullScreen />
          </div>
        </div>
      );
    } else if (this.props.panoDotProps && this.props.panoDotProps.data.img_url) {
      embedBlock = (
        <div className="modal-media">
          <img
            src={ this.props.panoDotProps.data.img_url }
            alt={ `Building located at address ${this.props.panoDotProps.data.address}` }
            aria-label={ `Building located at address ${this.props.panoDotProps.data.address}` }>
          </img>
        </div>
      );
    }

    if (this.props.panoDotProps && this.props.panoDotProps.data.timeline_file) {
      timelineModalBlock = (
        <div className="modal-body-centered">
          <button className="button" onClick={ this.handleTimelineModal }>Open Timeline</button>
        </div>
      );
    }

    if (isNull(this.props.panoDotProps)) {
      return '';
    } else {
      return (
        <div ref={elem => this.comp = elem} className={ this.state.showModal ? 'overlay-modal overlay-modal-show' : 'overlay-modal overlay-modal-hide'}>
          <div className="modal-outer-container">
            <div className="modal-body-container">
              { headerBlock }
              { embedBlock }
              <div className="modal-caption">
                <div className="modal-body">
                  <p dangerouslySetInnerHTML={ { __html: this.props.panoDotProps.data.caption } }></p>
                  { timelineModalBlock }
                </div>
                <div className="modal-close-button" aria-hidden="true" onClick={ this.handleCloseModal }>
                  <i className="fa fa-times"></i> Close
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
  }
}


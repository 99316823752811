import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import '../GenericPageLayout.css';

const AboutPage = () => {
  const history = useHistory();

  return (
    <div className="layout-page-container" id="about">
      <div className="layout-page">
        <div className="layout-about">
          <Link to="/">&laquo; Return to Exhibit</Link>
          <h1>About First Street North: Open</h1>
          <p>
            These living, continuously updated collections of personal stories, oral histories, documentary footage, and primary documents capture the necessity of Little Tokyo’s First Street North as a site of cultural, familial, and political importance. They detail how the diverse, multicultural communities of Little Tokyo lived their lives amidst backdrops of change and attempted redevelopment throughout distinct eras of history, like the brief existence of Bronzeville and the post-war resettlement of Little Tokyo, and attempts to recreate an image of daily life through the lenses of First Street North’s thirteen buildings.
          </p>
          <p>
            Within this backdrop of change, <strong>First Street North: Open</strong> recognizes First Street North’s presence on the traditional, ancestral, and unceded lands of the Gabrielino/Tongva People — the original stewards of the Los Angeles area — and honors both the land and generations of native peoples who cared for it throughout its history. FSN: Open is committed to a learning based on this history of displacement, and using this knowledge to learn how to better steward the former lands of the Gabrielino/Tongva people.
          </p>
          <p>
            These intimate personal accounts and other primary sources elucidate stories having taken place within, around, and about First Street North’s remaining thirteen buildings, and are a testament to the power of benign and familial storytelling as important forms of historical documentation. It allows us to record history from the ground-up - starting with individuals, families, businesses, and communities - in order to understand how real people within Little Tokyo navigated the complexities of a growing Los Angeles: a city which has historically failed to hold space for its underrepresented populations. FSN: Open wants to provide a vehicle to hold this space, and share these important histories through two lenses: the neighborhood's thirteen historic buildings and a conceptual basis of openness. FSN: Open is an open-source, curated platform for storytelling, sharing, and reflection, and seeks to open up a space where personal stories are valued and able to breathe freely.
          </p>
          <hr />
          <p>
            First Street North is a relatively new name, created within the last decade and popularized by the collective efforts of community organizations <a href="http://sustainablelittletokyo.org/" aria-label="Sustainable Little Tokyo" target="_blank" rel="noopener noreferrer">Sustainable Little Tokyo</a>, Little Tokyo Service Center, and Little Tokyo Community Council, used to describe the thirteen preserved buildings part of the Little Tokyo Historic District from 301 to 355 E First Street and 112 to 120 Judge John Aiso Street. Threatened by outside business and the loss of community control, the moniker First Street North was created as a rallying point to preserve and cultivate these fleeting spaces for and by the Little Tokyo community. FSN: Open seeks to continue to uphold the community values of Sustainable Little Tokyo, facilitating the practice of stewardship, belonging, collective and self-expression, well-being, and intergenerational movement building in order to ensure a holistic and equitable approach to this historic space.
          </p>
          <p>
            In 2018 and 2019, artists part of the +LAB Residency Program via Little Tokyo Service Center laid the groundwork for FSN: Open, creating projects and artworks centered around the themes of community control, self-determination, and redevelopment. Without the work of this collective of artists, FSN: Open would not exist today. Their works, like Dan Kwong’s Tales of Little Tokyo, have served as invaluable resources, featuring prominently as sources of inspiration and information, and weaving their influence throughout all of FSN: Open. We recognize the labor of these talented and caring individuals, and are grateful for the foundation they have built. <a href="https://vcmedia.org/latest-news?tag=%2BLAB" aria-label="+LAB residents" target="_blank" rel="noopener noreferrer">Click here to learn more about the +LAB residents</a>.
          </p>
          <p>
            A member of the 2018 group of artists, TT Takemoto, greatly influenced First Street North: Open, and laid the foundations necessary for this collection of stories. Their work traced out a history of Little Tokyo through commerce, analyzing the thirteen buildings and organizing a timeline of the different businesses that occupied the storefronts and residential spaces since the late 19th century. Moreover, FSN: Open’s namesake is rooted in Takemoto’s work, and would not exist without their research and diligent labor as a +LAB resident.
          </p>
          <p><img src="/assets/tina-fsn.jpg" alt="Tina Takemoto's +LAB resident work" aria-label="Tina Takemoto's +LAB resident work" /></p>
          <p>
            In 2019, First Street North was highlighted at the <a href="https://vcmedia.org/atfirstlight" aria-label="Link to At First Light" target="_blank" rel="noopener noreferrer"><em>At First Light: The Dawning of Asian Pacific America</em></a> exhibit at the Japanese American National Museum through filmmaker Tadashi Nakamura’s contemporary video installation FSN 1972. This installation featured a projection of a 1972 drawing of a group of four buildings on E First Street, embedding vintage video footage into the projection’s windows and doorways. The activation brought to life the role of memory within FSN, and breathed importance into the idea of cultural and historical continuity within the sights and stories of these historic buildings.
          </p>
          <p>We are grateful for the works by these various artists, and could not have formed a holistic project without their influence and labor.</p>

          <div style={{ paddingTop: '2em', textAlign: 'center' }}><button className="button-link" onClick={() => history.push('/')}>Return to Exhibit</button></div>
        </div>
      </div>
    </div>
  )
}

export default AboutPage;
import React from 'react';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw'
import './TimelineModal.css';
import '../GenericPageLayout.css';
import { PanoDotProps } from '../PanoDot/PanoDot';

type TimelineModalProps = {
  panoDotProps?: PanoDotProps;
  handleModalClose: Function;
  markdownUrl?: string;
}

type HeaderLine = {
  label: string;
  anchorName: string;
}
type TimelineModalState = {
  markdownContent: string;
  headerLines: HeaderLine[];
}

export class TimelineModal extends React.Component<TimelineModalProps, TimelineModalState> {

  constructor(props: any) {
    super(props);
    this.state = {
      markdownContent: '',
      headerLines: []
    };
  }

  componentDidMount() {
    document.addEventListener('keydown', this.escapeKeyCloseModal.bind(this));
    if (this.props.panoDotProps && this.props.panoDotProps.data.timeline_file) {
      this.fetchMarkdownContents(this.props.panoDotProps.data.timeline_file).then((markdownContent) => {
        this.setState({ markdownContent });
        this.generateHeaders();
      });
    }
  }

  handleCloseModal = () => {
    document.removeEventListener('keydown', this.escapeKeyCloseModal.bind(this));
    this.props.handleModalClose();
  }

  generateHeaders = () => {
    const markdownContent = this.state.markdownContent;
    const markdownLines: string[] = markdownContent.length > 0 ? markdownContent.split("\n") : [''];
    const rawHeaderLines = markdownLines.filter((line) => (line.trim().match(/^## /i) !== null));
    
    if (rawHeaderLines.length > 0) {
      const headerLines = rawHeaderLines.map((line, index) => {
        const anchors = line.match(/id="([a-z0-9-]+)"/i);
        return {
          label: line.replace(/^## /i, '').replaceAll(/(<([^>]+)>)/gi, '').trim(),
          anchorName: (anchors && anchors[1]) ? anchors[1] : `Section${index}`
        };
      });

      this.setState({ headerLines });
    }
  }

  fetchMarkdownContents = (timelineFile: string) => {
    return fetch(`${timelineFile}`).then((res) => {
      return res.text();
    });
  }

  escapeKeyCloseModal (event: any){
    if(event.keyCode === 27) {
      this.handleCloseModal();
    }
  }

  render() {
    let headerBlock = <ul className="timeline-toc-list"></ul>;
    
    if (this.state.headerLines.length > 0) {
      const headerLinks = [];
      for (const item of this.state.headerLines) {
        headerLinks.push((<li><a href={ `#${item.anchorName}` }>{ item.label }</a></li>));
      }
      headerBlock = <ul className="timeline-toc-list">
                      { headerLinks }
                    </ul>;
    }

    return (
      <div className="timeline-overlay-modal overlay-modal-show">
        <div className="timeline-modal-outer-container">
          <div className="timeline-modal-body-container">
              <div id="TimelineTop" className="timeline-modal-close-button" aria-hidden="true" onClick={ this.handleCloseModal }>
                <i className="fa fa-times"></i> Close
              </div>
              <div className="timeline-modal-body">
                <h1>{ this.props.panoDotProps?.data.address } &mdash; Timeline</h1>

                { headerBlock }

                <ReactMarkdown
                  rehypePlugins={[rehypeRaw]}
                  children={ this.state.markdownContent }
                  components={{
                    img: ({node, ...props}) => <p className="centered"><img alt="Building Dot" {...props} /></p>,
                    hr: ({node, ...props}) => <a className="timeline-back-to-top" href="#TimelineTop"><i className="fa fa-caret-up" aria-hidden="true"/> Back to Top</a>
                  }}
                />
              </div>
          </div>
        </div>
      </div>
    );
  }
}


import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import './BurgerMenu.css';

const BurgerMenu = () => {
  const [isSlideOut, toggleSlideOut] = useState(false);
  const menuClasses = `menu${isSlideOut ? ' slideout' : ''}`;
  const history = useHistory();

  const navigateTo = (where: string) => {
    toggleSlideOut(!isSlideOut);
    history.push(where);
  }

  return (
    <div className="menu-container">
      <div className="menu-icon" onClick={ () => toggleSlideOut(!isSlideOut) }>
        <i className="fa fa-bars" /> Menu
      </div>
      <div className={menuClasses}>
        <div className="menu-close-container">
          <span className="menu-close" onClick={ () => toggleSlideOut(!isSlideOut) }><i className="fa fa-close"></i> Close</span>
        </div>
        <ul className="burger-menu-list">
          <li className="burger-menu-list-item" onClick={() => navigateTo("/")}><i className="fa fa-home"></i> Home</li>
          <li className="burger-menu-list-item" onClick={() => navigateTo("/about")}>About</li>
          <li className="burger-menu-list-item" onClick={() => navigateTo("/contributions")}>Contributions</li>
          <li className="burger-menu-list-item" onClick={() => navigateTo("/contact-us")}>Contact Us</li>
        </ul>
      </div>
    </div>
  )
}

export default BurgerMenu;

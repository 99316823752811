import React from 'react';
import './PanoHintBox.css';

export type PanoHintBoxProps = {
  hideBox: boolean;
}

export class PanoHintBox extends React.Component<PanoHintBoxProps, {}> {

  render() {
    return (
      <div className={ `pano-hint-box ${ this.props.hideBox ? 'hide' : 'show'}` }>
        <p>Scroll or drag to explore.</p>
        <span><i style={{ fontSize: '2.5em'}} className="fa fa-arrows-h"></i></span>
      </div>
    )
  }
}
import React from 'react';
import { useHistory } from 'react-router-dom';
import '../GenericPageLayout.css';

const ContactUsPage = () => {
  const history = useHistory();

  return (
    <div className="layout-page-container" id="contact-us">
      <div className="layout-page">
        <div className="layout-contact-us">
          <h1>Contact Us</h1>
          <p>
            <strong>First Street North: Open</strong> is a Visual Communications production.
          </p>
          <p>Questions or comments? Do you have a story or memory about First Street North that you want to share? Feel free to contact us below:</p>
          <p>
            Visual Communications<br />
            120 Judge John Aiso St.<br />
            Los Angeles, CA 90012<br />
            <a href="mailto:info@vcmedia.org">info@vcmedia.org</a></p>
          <div style={ { paddingTop: '1em' } }>
            <button className="button-link" onClick={ () => history.push('/') }>Return to Exhibit</button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ContactUsPage;

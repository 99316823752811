import React from 'react';
import { useHistory } from 'react-router-dom';
import '../GenericPageLayout.css';

const ContributionsPage = () => {
  const history = useHistory();

  return (
    <div className="layout-page-container" id="contributions">
      <div className="layout-page">
        <div className="layout-contributions">
          <h1>Contributions</h1>
          <p>
            First Street North: Open is a living, continuously updated project based on communal curation, made possible by the insight and labor of multiple organizations and individuals who are committed to sharing stories of Little Tokyo’s historic thirteen buildings. Without their contributions, open access to these stories would not have been possible.
          </p>
          <p>Thank you to these individuals:
          </p>
          <p>
            <ul>
              <li>Dan Kwong, <a href="http://www.dankwong.com/" aria-label="Dan Kwong" target="_blank" rel="noopener noreferrer">dankwong.com</a></li>
              <li>Kaia Rose, <a href="https://www.kaiarose.com/" aria-label="Kaia Rose" target="_blank" rel="noopener noreferrer">kaiarose.com</a></li>
              <li>Tina Takemoto, <a href="http://ttakemoto.com/" aria-label="Tina Takemoto" target="_blank" rel="noopener noreferrer">ttakemoto.com</a></li>
              <li>Jocelyn Song</li>
              <li>Bill Watanabe</li>
              <li>Mike Okamura</li>
              <li>Brandon Waldau</li>
            </ul>
          </p>
          <p>
            Thank you to these partners: Densho, FORM follows FUNCTION, Little Tokyo Community Council, Little Tokyo Historical Society, Sustainable Little Tokyo, Little Tokyo Service Center
          </p>
          <div style={ { paddingTop: '1em' } }>
            <button className="button-link" onClick={ () => history.push('app') } >Return to Exhibit</button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ContributionsPage;
import React from 'react';
import './PanoDot.css'

export type PanoDotProps = {
  percentX: string;
  percentY: string;
  data: {
    vimeo_id?: string;
    img_url?: string;
    name?: string;
    caption: string;
    address: string;
    url_slug?: string;
    timeline_file?: string;
  };
  panoDimensions: {
    x: number;
    y: number;
  };
  handleModalOverlay: any;
}

export class PanoDot extends React.Component<PanoDotProps, any> {

  render() {
    const left = parseFloat(this.props.percentX) / 100 * this.props.panoDimensions.x;
    const top = parseFloat(this.props.percentY) / 100 * this.props.panoDimensions.y;
    return (
      <div className="pano-dot-container" onClick={ () => this.props.handleModalOverlay(this.props) } style={{ left, top }}>
        <div className="pano-dot"/>
      </div>
    );
  }
}
